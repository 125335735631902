import styled from "styled-components";

const SectionWrapper = styled.div`
  padding: 81px 0 0;
  background: #f5f5f5;

  .col-md-4 .showmob {
    display: none;
  }

 
  

  p.subheadstar span {
    font-family: "Mulish";
    font-weight: 500;
    font-size: 16px;
    line-height: 20.08px;
    color: #000000;
    margin-left: 12px;
  }

  .gReviewsIcos .swiper-button-prevG {
    position: absolute;
    bottom: 35%;
    z-index: 9;
    left: -20px;
  }
  .gReviewsIcos .swiper-button-nextG {
    position: absolute;
    bottom: 35%;
    z-index: 9;
    right: -20px;
  }

  .swiper-button-nextG.swiper-button-disabled,
  .swiper-button-prevG.swiper-button-disabled {
    opacity: 0.5;
  }
  .swiper-button-nextG,
  .swiper-button-prevG {
    color: #a8600e;
    border: 1px solid currentColor;
    padding: 5px 10px;
    border-radius: 100%;
    margin: 5px;
  }

  h3.title {
    font-weight: 700 !important;
    font-size: 26px;
    color: #000000;
    max-width: 430px;
  }

  svg {
    font-size: 16px;
  }

  .flex {
    a.btn.btn-default.btn-lg.black {
      background: linear-gradient(180deg, #131313 0%, #363636 100%);
      color: #ffffff;
      padding: 12px 45.1px;
      font-weight: 800;
      font-family: "Mulish";
    }
  }

  .swiper-wrapper {
    padding-top: 55px;
  }

  .gCard {
    background: #ffffff;
    padding: 30px;
    box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.1);
    border-radius: 20px;

    p#scrollable {
      max-height: 82px;
      overflow-y: scroll;
      min-height: 82px;
      margin-top: 35px;
    }

    p#scrollable {
      font-family: Mulish;
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: left;
    }

    .reviewPara img {
      width: 111px;
      position: absolute;
      margin-top: -65px;
      margin-left: -10px;
    }

    .gHead {
      text-align: center;
      position: absolute;
      left: 50%;
      top: 0;
      transform: translate(-50%, -50%);

      img {
        width: 70px;
      }
    }

    .gFooter {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;

      h5 {
        margin-top: 7px;
        margin-bottom: 0;
        font-family: Mulish;
        font-size: 18px;
        font-weight: 600 !important;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
      }

      .gHeadContent svg.svg-inline--fa.fa-star {
        color: #ffa238;
        font-size: 20px;
      }

      .gHeadContent p {
        margin-bottom: 0;
        font-family: Mulish;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
        color: #000000;
      }

      img {
        width: 50px;
        height: 50px;
        opacity: 50%;
      }
    }
  }

  .gr-row {
    display: flex;
    justify-content: space-between;
  }
  .leftGarea svg {
    color: #ffa238;
    font-size: 18px;
    margin: 0 2px;
  }

  .googleRview img {
    width: 32px;
    display: block;
    margin: 0 auto;
    margin-bottom: 15px;
  }
  .googleRview {
    text-align: center;
  }
  .googleRview svg {
    color: #efab4c;
  }
  .googleRview p {
    margin-top: 15px;
    font-size: 14px;
    line-height: 1.6;
  }
  .googleRview {
    border: 1px solid #dedede;
    border-radius: 10px;
    padding: 25px 15px;
    background: #ffffff;
  }
  .reviewHeadG h4 {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 0;
  }
  .reviewHeadG span {
    font-size: 13px;
  }
  .reviewHeadG img {
    border-radius: 100%;
    margin-top: 15px !important;
  }


  @media only screen and (max-width: 1440px) {
    padding: 60px 0 0;

    h3 {
      font-size: 28px;
      line-height: 32px;
      font-weight: 500;
    }
  }
  @media only screen and (max-width: 767px) {
    padding: 0px 0 20px;

    .col-md-4 .hidemob {
      display: none;
    }

    .col-md-4 .showmob {
      display: block;
    }

    .gReviewsIcos .swiper-button-prevG {
      position: absolute;
      bottom: -15%;
      z-index: 9;
      left: 36%;
    }

    .gReviewsIcos .swiper-button-nextG {
      position: absolute;
      bottom: -15%;
      z-index: 9;
      right: 36%;
    }

    .flex {
      flex-direction: column;
      align-items: flex-start;
      margin-top: 30px;
      padding-left: 10px;
      padding-right: 10px;

      a.btn.btn-default.btn-lg.black {
        margin-left: 0 !important;
      }
    }
  }

  @media only screen and (min-width: 767px) {
    .flex {
      display: flex;
      align-items: center;
    }
  }
`;

export const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  // display: flex;
  // align-items: center;
  @media only screen and (max-width: 1700px) {
    padding: 0 0px;
  }
  @media only screen and (max-width: 1360px) {
    padding: 0;
  }
  @media only screen and (max-width: 767px) {
    flex-direction: row;

    .swiper-wrapper {
      padding-top: 40px;
    }
  }
`;

export const ImageWrapper = styled.div`
  width: calc(100% - 507px);
  padding-right: 75px;
  position: relative;
  @media only screen and (max-width: 1200px) {
    width: 60%;
    padding-right: 50px;
  }
  @media only screen and (max-width: 991px) {
    padding-right: 0;
  }
  @media only screen and (max-width: 767px) {
    width: 100%;
    padding-right: 0;
    margin-bottom: 30px;
    transform: rotateY(-180deg);
  }
`;

export const TextWrapper = styled.div`
  width: 507px;
  @media only screen and (max-width: 991px) {
    padding-left: 50px;
  }
  @media only screen and (max-width: 767px) {
    padding-left: 0;
    width: 100%;
  }

  h2 {
    @media only screen and (min-width: 1441px) {
      margin-bottom: 60px;
    }
    @media only screen and (min-width: 1440px) {
      margin-bottom: 40px;
    }
    @media only screen and (min-width: 767px) {
      margin-bottom: 30px;
    }
  }

  p {
    margin-bottom: 30px;
  }

  .learn__more-btn {
    margin-top: 20px;
  }
    
`;

export default SectionWrapper;
