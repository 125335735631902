import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";

import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";

import SwiperCore, { Navigation, Pagination, Scrollbar, A11y, Autoplay,} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

import Fade from "react-reveal/Fade";
import SectionWrapper, { Container } from "./googleReviews.style";

// Install Swiper modules
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay]);



const GoogleReviews = () => {
  const allGoogleReviews = [
    {
      title: "Mrinal Mehta",
      firstlatter: "M",
      description:
        "I would highly recommend them. They are doing awesome services and having very caring and helpful staff. They are the best in there work.Thank you team",
      date: "2 months ago",
    },
    {
      title: "Adarsh Shukla",
      firstlatter: "A",
      description:
        "Good Experience. Staff were polite and service was done properly. Cost was bit high. I will highly recommend it.",
      date: "a week ago",
    },
    {
      title: "fahad asif",
      firstlatter: "F",
      description:
        "If  u want to look the same as you were before then Radiance is the only place. You will never be disappointed and the place is cool great staff and they treat u very friendly manner. so please go there if u wanna feel the change and be young again",
      date: "a month ago",
    },
    {
      title: "Praveen",
      firstlatter: "P",
      description:
        "I recently had the pleasure of getting a customized hair wig for women in Delhi, and I couldn't be happier with the experience. The process was smooth, and the final result was nothing short of flawless. The wig matched my natural hair color and texture perfectly, making it virtually indistinguishable from my real hair. It's incredibly comfortable to wear and allows me to experiment with different styles effortlessly. If you're in Delhi and looking for a high-quality customized wig, this is the place to go.",
      date: "2 weeks ago",
    },
    {
      title: "Prem Pandey",
      firstlatter: "P",
      description:
        "Hi, I have started using Hair Patch from Mar 22 from Radiance. The beginning was scary about the patch but I got used to it day by day.The technician were cooperative specially Deepak is patient enough to adjust the hair style which I want and fixed the same.Aatika is The person who explained me the process and explained me how  can maintain it properly for a longer life was really helpful and thanks to herIt is cost effective for the patch except the monthly maintenance cost though",
      date: "2 month ago",
    },
    {
      title: "Sunil Rastogi",
      firstlatter: "S",
      description:
        "I had an amazing experience at Radiance Hair Studio in Delhi! Their hair wigs are of exceptional quality, look completely natural, and are so comfortable to wear. The staff is professional and truly understands customer needs, offering great advice and personalized solutions. Highly recommend Radiance for anyone looking for premium wigs in Delhi!",
      date: "2 month ago",
    },
    {
      title: "Aarav Gupta",
      firstlatter: "A",
      description:
        "I had an amazing experience at Radiance Hair Studio in Delhi! The quality of their hair wigs is outstanding, and they look so natural. The staff was incredibly helpful in guiding me to choose the right wig that suited my style and preferences. Highly recommend their services!",
      date: "2 weeks ago",
    },
    {
      title: "Shasan Ali",
      firstlatter: "S",
      description:
        "I took a hair patch from this place.. these guys are amazing. I am very happy with there services.",
      date: "2 month ago",
    },
    {
      title: "Riya Singh",
      firstlatter: "R",
      description:
        "I got my hair wig from Radiance Hair Studio, and the results are amazing! The wig looks so natural, and it boosted my confidence instantly. Highly recommend for anyone looking for quality and professionalism!",
      date: "a week ago",
    },
    {
      title: "Sneha Mandal",
      firstlatter: "S",
      description:
        "Friendly and Good behaving Staff. Experienced Hair Stylists. Quality Products Worth for money.Overall Had a Great Experience",
      date: "a month ago",
    },
    {
      title: "Arjun Mehta",
      firstlatter: "A",
      description:
        "I visited Radiance Hair Studio for a hair wig consultation, and I couldn’t be happier with the results! The wig I got blends perfectly with my natural hair, and it's lightweight and comfortable. The service was professional, and they took the time to customize the wig to fit me perfectly. Best place in Delhi for hair wigs!",
      date: "2 weeks ago",
    },
    {
      title: "Aditya Reddy",
      firstlatter: "A",
      description:
        "I had an amazing experience at Radiance Hair Studio! The hair extensions look so natural and have completely transformed my look. The team was incredibly professional and made sure I was comfortable throughout the process. Highly recommend for anyone looking for quality hair extensions in Delhi!",
      date: "3 month ago",
    },
    {
      title: "The Kaida",
      firstlatter: "T",
      description:
        "I Would Highly Recommend Them.They are a very caring and wonderful staff..They are the best...See You Soon Thanks Again..",
      date: "a month ago",
    },
    {
      title: "Shivam Sharma",
      firstlatter: "S",
      description:
        "I am regular a customer to Radiance Hair Studio..... Mr Deepak always guides me the choice my hair style he is very friendly and supportive guy always ensure to meet my requirements",
      date: "2 month ago",
    },
    {
      title: "Kavya Choudhary",
      firstlatter: "K",
      description:
        "Radiance Hair Studio is the best for hair bonding! My hair has never looked better. The bonding technique they used gave me a natural, voluminous look, and the service was excellent. Super happy with the results!",
      date: "2 weeks ago",
    },
    {
      title: "Meera Nair",
      firstlatter: "/M",
      description:
        "I had an amazing experience with hair weaving at Radiance Hair Studio! The staff is extremely professional, and the results are beyond my expectations. My hair looks so natural, and I feel more confident than ever. Highly recommend them for anyone looking to restore their hair's volume and beauty!",
      date: "a week ago",
    },
  ];

  return (
    <SectionWrapper id="googleReviews">
      <Container className="myContainer">
        <div className="row">
          <div className="col-md-4">
          <Fade top>
              <img
                className="greviewimg showmob"
                width="404"
                height="434"
                src="/service-images/Reviewss.jpg"
                alt="google reviews"
                style={{ marginTop: "10px" }}
              />
            </Fade>
            <Fade left>
              <img
                className="greviewimg hidemob"
                width="404"
                height="434"
                src="/home/review.webp"
                alt="google reviews"
                style={{ marginTop: "10px" }}
              />
            </Fade>
          </div>
          <div className="col-md-8">
            <div
              className="reviewFold"
              id="googleReviews"
              style={{ display: "block" }}
            >
              <div className="flex">
                <Fade left>
                  <div className="leftGarea">
                    <h3 className="title">Highest Number of Google Rating in Delhi-NCR in This Segment</h3>
                    <p className="subheadstar">
                      <FontAwesomeIcon icon={faStar} />
                      <FontAwesomeIcon icon={faStar} />
                      <FontAwesomeIcon icon={faStar} />
                      <FontAwesomeIcon icon={faStar} />
                      <FontAwesomeIcon icon={faStar} />
                      <span>4.9 Rating of 2000+ Reviews</span>
                    </p>
                  </div>
                </Fade>
                <Fade right>
                  <a
                    href="https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJc5hFOhUDDTkR84Qd5N0PQ58"
                    target="_blank"
                    rel="noreferrer"
                    className="btn btn-default btn-lg black"
                    style={{ marginLeft: "auto" }}
                  >
                    Write a Review
                  </a>
                </Fade>
              </div>
              <div className="divider" style={{ margin: "15px auto" }}></div>
              <Fade bottom>
                <div
                  className="swiperOverfloww"
                  style={{ marginBottom: "60px" }}
                >
                  <div className="flex gReviewsIcos">
                    {/* Custom navigation buttons */}
                    <div className="swiper-button-prevG">
                      <FontAwesomeIcon icon={faArrowLeft} />
                    </div>
                    <div className="swiper-button-nextG">
                      <FontAwesomeIcon icon={faArrowRight} />
                    </div>
                  </div>
                  <Swiper
                    spaceBetween={30} // Adjust as needed
                    slidesPerView={2} // Display 4 slides per view
                    // autoplay={{ delay: 3000 }}
                    navigation={{
                      prevEl: ".swiper-button-prevG",
                      nextEl: ".swiper-button-nextG",
                    }}
                    // pagination={{ clickable: true }}
                    breakpoints={{
                      300: {
                        // width: 768,
                        slidesPerView: 1,
                      },
                      768: {
                        // width: 768,
                        slidesPerView: 2,
                      },
                    }}
                  >
                    {allGoogleReviews.map((review, index) => (
                      <SwiperSlide key={index}>
                        <div className="gCard">
                          <div className="gHead">
                          <p className="mb-0"> {review.firstlatter}</p>
                            {/* <img
                              loading="lazy"
                              width="101"
                              height="101"
                              src={review.image}
                              alt={`Reviewimg for ${review.title}`}
                            /> */}
                          </div>
                          <p className="reviewPara" id="scrollable">
                            <img
                              loading="lazy"
                              width="111"
                              height="111"
                              src="/home/quote.svg"
                              alt={`Reviewimg for ${review.title}`}
                            />
                            {review.description}
                          </p>
                          <div className="gFooter">
                            <div className="gHeadContent">
                              <FontAwesomeIcon icon={faStar} />
                              <FontAwesomeIcon icon={faStar} />
                              <FontAwesomeIcon icon={faStar} />
                              <FontAwesomeIcon icon={faStar} />
                              <FontAwesomeIcon icon={faStar} />
                              <h5>{review.title}</h5>
                              <p>{review.date}</p>
                            </div>
                            <img
                              width="68"
                              height="68"
                              src="/google.png"
                              alt="Review 1"
                            />
                          </div>
                        </div>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
              </Fade>
            </div>
          </div>
        </div>
        
      </Container>
    </SectionWrapper>
  );
};

export default GoogleReviews;
