import React, { Fragment } from "react";
import { ThemeProvider } from "styled-components";
import { interiorTheme } from "common/theme/interior";

import Titlebar from "common/components/Titlebar";
import GoogleReviews from 'containers/Home/GoogleReviews';
import "common/assets/css/main-page.css";

import Seo from "components/seo";
import Layout from "../containers/Home/Layout";

const TestimonialsPage = () => {

  const seoData = {
    title: 'Testimonials & Reviews - Radiance Hair Studio',
    description: 'If you are looking for a hair replacement, please have a look at the recent deliverables that we provided to our clients. For more information call us now.',
    keywords: ['Testimonials'],
  };

  const schemaData = {
    "@context": "https://schema.org",
    "@type": "Service",
    "@id": "Service",
    "areaServed": "New Delhi",
    "image": [
      "https://www.radiancehairstudio.com/home/review.webp",
      "https://www.radiancehairstudio.com/home/home-image-18.webp"
    ],
    "logo": "https://www.radiancehairstudio.com/static/radiance-logo-721b0cd4def3d400c04ba899afd38810.png",
    "additionalType": "https://www.radiancehairstudio.com/testimonials.php",
    "alternateName": "Testimonials & Reviews",
    "category": "Testimonials",
    "description": "If you are looking for a hair replacement, please have a look at the recent deliverables that we provided to our clients. For more information call us now.",
    "disambiguatingDescription": "Testimonials"
  };   

  return (
    <ThemeProvider theme={interiorTheme}>
      <Fragment>
          <Seo {...seoData} schemaData={schemaData} />
        <Layout>
            <Titlebar title="Testimonials & Reviews" />
            <GoogleReviews />
        </Layout>
      </Fragment>
    </ThemeProvider>
  );
};
export default TestimonialsPage;